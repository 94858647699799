import React from 'react';

const MessageComponent = ({ message }) => {
    const senderName = message.sender === 'VenvAI' ? 'VenAI' : 'Me';
    const className = message.sender === 'VenvAI' ? 'message sender' : 'message receiver';
    return (
        <div className={className}>
            <h3 className='heading'>{senderName}</h3>
            <p>{message.message}</p>
        </div>
    );
};

export default MessageComponent;
