import { useState } from 'react'
import './App.css'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import Protected from './protected';
import ParticleBackground from '../components/ParticleBackground';
import MessageComponent from '../components/message';
import send from './send.png'
import { UNSAFE_LocationContext } from 'react-router-dom';
const API_KEY = "sk-proj-Q9RQDpB4LunKNLGUZJe3T3BlbkFJSskKDdfR6OAJmHCHYNrh";
const systemMessage = [
{
  "role":"user", "content": "VIA G. ROSSINI 1/A 20020 - LAINATE (MI) Trattativa n. 23/0000011599 Tel. 02/90394188 Fax"
},

{
  "role":"assistant", "content": "Street: Via G. Rossini 1/A \nPostal Code: 20020\nCity: Lainate\nProvince: Milan (MI is the abbreviation for Milano, which is the Italian name for Milan)\nPhone: 02/90394188 \nFax: 0444 707176 "
},
{
  "role":"user", "content": "VIA G. ROSSINI 1/A 20020 - LAINATE (MI) Trattativa n. 23/0000011599 Tel. 02/90394188 Fax"
},
{
  "role":"assistant", "content": "Street: Via G. Rossini 1/A \nPostal Code: 20020\nCity: Lainate\nProvince: Milan (MI is the abbreviation for Milano, which is the Italian name for Milan)\nPhone: 02/90394188 \nFax: 0444 707176 "
}]

function VenvGpt() {
  const [messages, setMessages] = useState([
    {
      message: 'Hi, I am here to help you with your address. Please paste your address below and I will try to parse it for you.',
      sender: 'VenvAI',
    },

    
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user"
    };

    const newMessages = [...messages, newMessage];
    
    setMessages(newMessages);

    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) { // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "VenvAI") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message}
    });


    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act. 
    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        ...systemMessage,  // The system message DEFINES the logic of our chatGPT
        ...apiMessages // The messages from our chat with ChatGPT
      ]
    }

    await fetch("https://api.openai.com/v1/chat/completions", 
    {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    }).then((data) => {
      return data.json();
    }).then((data) => {
      console.log(data);
      setMessages([...chatMessages, {
        message: data.choices[0].message.content,
        sender: "VenvAI",
      }]);
      setIsTyping(false);
    });
  }

  function clearMessages() {
    setMessages([]);
  }
  function downloadMessages() {

    let text = "";
    messages.forEach((message) => {
      text += message.sender + ": " + message.message + "\n";
    });
    const element = document.createElement("a");
    const file = new Blob([text], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "chat.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    

  }
  return (
          // <Protected>
        <div className='center-container'>
            <div className='title-bar'>
            &nbsp;&nbsp;
                <button onClick={clearMessages} id="clearButton">Clear Chat</button>
                &nbsp;&nbsp;
                <button onClick={downloadMessages } id="downloadButton">Download Chat</button>
                {/* <button onClick={()=>{} } id="logout">Log out</button> */}
                 
                  <button href="/login" onClick={()=>{
                    localStorage.removeItem("loggedIn")
                    localStorage.removeItem("token")
                    window.location.href="/login"
                  }} id="logout">Log out</button>
                 
                 </div>
            <ChatContainer>
                
          <MessageList
              scrollBehavior="smooth"
              typingIndicator={isTyping ? <TypingIndicator content="VenvAI is typing" /> : null}
          >
              {messages.map((message, i) => {
                  console.log(message);
                  return <MessageComponent key={i} message={message} />             })}
          </MessageList>
          
      </ChatContainer>
      <div className='message-input-container'> 
    <form className='message-form' onSubmit={(e) => {
          e.preventDefault();
      if (e.target.message.value !== "") {
        handleSend(e.target.message.value);
        e.target.message.value = "";
      
        }}
        }>
        <input maxlength="500" className='text-field' type="text" name="message" placeholder="Paste address here..." />
        <button type="submit" className='submit'><img src={send} height="30" width="30"></img></button>
    </form></div>
      </div>
      // </Protected> 
  )
}

export default VenvGpt    