import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import VenvGpt from './pages/venvGpt.jsx';

function App() {
  
  return (
    <VenvGpt />
  )
}

export default App    